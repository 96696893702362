<template>
  <v-card class="mb-2"
          elevation="2"
  >
    <v-container class="mb-0 pb-0" v-if="!emptyStock">
      <v-row
        justify="space-between"
      >
        <v-col cols="8">
          <span>
            Склад:
          </span>
        </v-col>
        <v-col cols="4">
          <v-btn
            text
            outlined
            small
            color="dark"
            @click="editMode = !editMode"
          >
            {{ editDataButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text
      v-if="emptyStock || editMode"
      class="text--primary"
    >
      <v-text-field
        v-model="stockInfo.name"
        outlined
        :label="'* Название'"
        :rules="nonEmptyRules">
      </v-text-field>
      <v-text-field
        v-model="stockInfo.city"
        outlined
        :label="'* Город'"
        :rules="nonEmptyRules">
      </v-text-field>
      <v-row>
        <v-col cols="6">
          <v-btn
            color="dark"
            class="mt-2"
            @click="saveStock">
            Сохранить
          </v-btn>
        </v-col>
        <v-col cols="6" v-if="!emptyStock">
          <v-btn v-if="!stockInfo.orders_to.length > 0"
                 color="dark"
                 class="mt-2"
                 @click="deleteStock">
            Удалить
          </v-btn>
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-text
      v-else-if="!editMode"
      class="text--primary">
      <div class="account-text-info">
        <span class="font-weight-medium">Название</span>
        {{ stockInfo.name }}
      </div>
      <div class="account-text-info">
        <span class="font-weight-medium">Город</span>
        {{ stockInfo.city }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import account from "@/api/account";
import {mapActions} from "vuex";

export default {
  name: "StockInfoCard",
  data() {
    return {
      stockInfo: {...this.stock},
      stocksArray: {...this.stocks},
      editMode: false,
    };
  },
  props: {
    stock: {
      default: () => ({
        city: String,
        id: Number,
        name: String,
        orders_to: Array,
      }),
      stocks: Array,
    },
    emptyStock: {
      default: false,
    },
    nonEmptyRules: {
      type: Array,
    },
  },
  computed: {
    editDataButtonText() {
      return this.editMode ? 'Отменить' : 'Изменить';
    },
  },
  methods: {
    ...mapActions(["alertSuccess", 'alertError']),
    ...mapActions('auth', ['updateUserinfo']),
    validateStockInfo: function () {
      if (this.stockInfo.name && this.stockInfo.city) {
        return true;
      }
      this.alertError('Пожалуйста, заполните все обязательные поля');
      return false;
    },
    saveStock() {
      if (this.validateStockInfo()) {
        if (this.stockInfo.id) {
          this.updateStock();
        } else {
          this.createStock();
        }
        location.reload();
      }
    },
    updateStock() {
      account.changeAccountStockInfo(this.stockInfo)
        .then((response) => {
          this.stockInfo = response.data;
          this.editMode = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createStock() {
      account.createAccountStockInfo(this.stockInfo)
        .then(function () {
          this.stockInfo = {}
          this.$emit('getStocks');
        }.bind(this))
        .catch((error) => {
          console.log(error);
        });
    },
    deleteStock() {
      account.deleteAccountStockInfo(this.stockInfo.id)
        .then(function () {
          this.$emit('getStocks');
        }.bind(this))
        .catch((error) => {
          console.log(error);
        });
    },
  },
}

</script>

<style scoped>

</style>
