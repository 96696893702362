<template>
  <v-col cols="12"
         md="12"
         lg="4"
  >
    <v-card
      elevation="2"
    >

      <v-card-title>
        <v-row>
          <v-col
            cols="8"
          >
            Пользователь:
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              text
              outlined
              small
              color="dark"
              @click="editMode = !editMode"
            >
              {{ editDataButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text
        class="text--primary"
      >
        <div class="account-text-info pb-2">
          {{ userInfo.username }} ({{ userInfo.group_name }})
        </div>
        <div v-if="!editMode">
          <div class="account-text-info">
            <span class="font-weight-medium">Имя:</span>
            {{ userInfo.first_name }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Фамилия:</span>
            {{ userInfo.last_name }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Почта:</span>
            {{ userInfo.email }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Телефон:</span>
            {{ userInfo.phone }}
          </div>
        </div>
        <div v-else>
          <v-text-field
            v-model="userInfo.first_name"
            outlined
            label="* Имя"
            :rules="nonEmptyRules"
          />
          <v-text-field
            v-model="userInfo.last_name"
            outlined
            label="* Фамилия"
            :rules="nonEmptyRules"
          />
          <v-text-field
            v-model="userInfo.email"
            outlined
            label="* Почта"
            type="email"
            :rules="emailRules"
          />
          <v-text-field
            v-model="userInfo.phone"
            type="tel"
            outlined
            :rules="phoneRules"
            label="* Телефон"
          />
          <v-btn
            text
            outlined
            color="dark"
            @click="updateUserInfo"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import account from "@/api/account";

export default {
  name: "UserInfoCard",
  props: {
    phoneRules: {
      type: Array,
    },
    emailRules: {
      type: Array,
    },
    nonEmptyRules: {
      type: Array,
    },
    user: {
      default: () => ({
        id: '',
        email: '',
        first_name: '',
        group_name: '',
        last_name: '',
        phone: '',
        username: '',
      }),
    },
  },
  data() {
    return {
      editMode: false,
      userInfo: {...this.user},
    };
  },
  computed: {
    editDataButtonText() {
      return this.editMode ? 'Отменить' : 'Изменить';
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    async updateUserInfo() {
      try {
        const response = await account.changeAccountUserInfo(this.userInfo);
        this.userInfo = response.data;
        this.$emit("update:user", this.userInfo);
        this.editMode = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getUserInfo() {
      try {
        const response = await account.getAccountUserInfo();
        this.userInfo = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>


<style scoped>
.account-text-info {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
</style>
