<template>
  <v-main>
    <v-container me-2 mt-2>
      <h2>Личный кабинет</h2>
      <v-row>
        <UserInfoCard
          :phone-rules="phoneRules"
          :email-rules="emailRules"
          :non-empty-rules="nonEmptyRules"
        />
        <CompanyInfoCard
          v-if="isWholesalePartner"
          :phone-rules="phoneRules"
          :email-rules="emailRules"
          :non-empty-rules="nonEmptyRules"
        />
        <StockInfo
          v-if="getUserCompany > 0 && isWholesalePartner"
          :non-empty-rules="nonEmptyRules"
        />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {mapGetters} from "vuex";
import UserInfoCard from "@/components/account/UserInfoCard.vue";
import CompanyInfoCard from "@/components/account/CompanyInfoCard.vue";
import StockInfo from "@/components/account/StockInfo.vue";

export default {
  name: "AccountView",
  components: {UserInfoCard, CompanyInfoCard, StockInfo},

  computed: {
    ...mapGetters("auth", ["isMarketplaceManager", 'getUsername', 'getUserCompany', 'getUserGroup', 'isStaff', 'isWholesalePartner']),
    phoneRules() {
      return [
        v => /^\+\d{11}$/.test(v) || 'Номер телефона нужно писать с +'
      ]
    },
    emailRules() {
      return [
        v => !!v || 'Это поле нужно заполнить',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'Неправильный формат почты'
      ]
    },
    nonEmptyRules() {
      return [
        v => !!v || 'Это поле нужно заполнить'
      ]
    },
  },
  methods: {},
}

</script>

<style scoped>

</style>
