<template>
  <v-col cols="12"
         md="12"
         lg="4">
    <v-card elevation="2">
      <v-card-title>
        <v-row>
          <v-col
            cols="8"
          >
            Компания:
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              text
              outlined
              small
              color="dark"
              @click="editMode = !editMode"
            >
              {{ editDataButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text--primary">
        <div v-if="!editMode && company.id">
          <p class="account-text-header">Юр. Информация</p>
          <div class="account-text-info">
            <span class="font-weight-medium">Юр. лицо</span>
            {{ company.name }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Email:</span>
            {{ company.email }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Страна:</span>
            {{ company.country_label }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Город:</span>
            {{ company.city }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Адрес:</span>
            {{ company.address }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Номер телефона:</span>
            {{ company.phone }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">ОГРНИП:</span>
            {{ company.ogrnip }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Номер договора:</span>
            {{ company.contract_number }}
          </div>
          <p class="account-text-header">Банковские данные</p>
          <div class="account-text-info">
            <span class="font-weight-medium">Банковский счет:</span>
            {{ company.bank_account }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">Банк:</span>
            {{ company.bank_name }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">БИК:</span>
            {{ company.bank_bik }}
          </div>
          <div class="account-text-info">
            <span class="font-weight-medium">ИНН банка:</span>
            {{ company.bank_inn }}
          </div>
        </div>
        <div v-else-if="!editMode & !company.id">
          <div class="account-text-info">
            <span class="font-weight-medium">Пожалуйста, заполните информацию о компании</span>
          </div>
        </div>
        <div v-else>
          <p class="account-text-header">Юр. Информация</p>
          <v-text-field
            v-model="company.name"
            outlined
            required
            label="* Юр. лицо"
            :rules="nonEmptyRules"
          >
          </v-text-field>
          <v-text-field
            v-model="company.email"
            outlined
            :rules="emailRules"
            required
            label="* Email"
          >
          </v-text-field>
          <v-select
            v-model="company.country"
            :rules="nonEmptyRules"
            item-text="state"
            item-value="abbr"
            :items="countries"
            outlined
            required
            persistent-hint
            single-line
            prepend-inner-icon="mdi-earth"
            label="* Страна"
          >
          </v-select>
          <v-text-field
            v-model="company.city"
            outlined
            :rules="nonEmptyRules"
            required
            label="* Город"
          >
          </v-text-field>
          <v-text-field
            v-model="company.address"
            outlined
            :rules="nonEmptyRules"
            label="* Адрес"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="company.phone"
            outlined
            :rules="phoneRules"
            required
            label="* Номер телефона"
          >
          </v-text-field>
          <v-text-field
            v-model="company.ogrnip"
            outlined
            label="ОГРНИП"
          />
          <v-text-field
            v-model="company.contract_number"
            outlined
            label="Номер договора"
          />
          <p class="account-text-header">Банковские данные</p>
          <v-text-field
            v-model="company.bank_account"
            outlined
            label="Банковский счет"
          />
          <v-text-field
            v-model="company.bank_name"
            outlined
            label="Банк"
          />
          <v-text-field
            v-model="company.bank_bik"
            outlined
            label="БИК"
          />
          <v-text-field
            v-model="company.bank_inn"
            outlined
            label="ИНН банка"
          />
          <v-btn
            text
            outlined
            color="dark"
            @click="saveAccountCompany"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import account from "@/api/account";
import {mapActions} from "vuex";

export default {
  name: "CompanyInfoCard",
  props: {
    phoneRules: {
      type: Array,
    },
    emailRules: {
      type: Array,
    },
    nonEmptyRules: {
      type: Array,
    },
  },
  data() {
    return {
      company: {
        id: '',
        name: '',
        country: '',
        city: '',
        address: '',
        email: '',
        phone: '',
        contract_number: '',
        contract_date: '',
        inn: '',
        ogrnip: '',
        company_type: '',
        bank_account: '',
        bank_name: '',
        bank_bik: '',
        bank_inn: '',
      },
      selectedCountry: {
        state: 'Россия',
        abbr: 'RU'
      },
      countries: [
        {state: 'Россия', abbr: 'RU'},
        {state: 'Казахстан', abbr: 'KZ'},
        {state: 'Белоруссия', abbr: 'BY'},
      ],
      editMode: false,
    }
  },
  computed: {
    editDataButtonText() {
      return this.editMode ? 'Отменить' : 'Изменить';
    },
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    ...mapActions("auth", ["updateUserinfo"]),
    ...mapActions(["alertSuccess", 'alertError']),
    validateCompanyInfo: function () {
      if (this.company.name &&
        this.company.country &&
        this.company.city &&
        this.company.address &&
        this.company.email &&
        this.company.phone) {
        return true;
      }
      this.alertError('Пожалуйста, заполните все обязательные поля');
      return false;
    },
    saveAccountCompany: async function () {
      if (!this.validateCompanyInfo()) {
        return;
      }
      if (this.company.id) {
        await this.updateUserCompany();
      } else {
        await this.createUserCompany();
      }
    },
    updateUserCompany: async function () {
      try {
        const response = await account.changeAccountCompanyInfo(this.company);
        this.company = response.data;
        this.editMode = false;
      } catch (e) {
        console.log(e);
      }
    },
    createUserCompany: async function () {
      try {
        const response = await account.createAccountCompanyInfo(this.company);
        this.company = response.data;
        this.editMode = false;
        await this.updateUserinfo();
      } catch (e) {
        console.log(e);
      }
    },
    getCompanyInfo: async function () {
      try {
        const response = await account.getAccountCompanyInfo();
        this.company = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    setSelectedCountry() {
      this.company.country = this.selectedCountry.abbr
    },
  },
}
</script>

<style scoped>
.account-text-info {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.account-text-header {
  font-size: 1.2rem;
  font-weight: 400;
  color: darkgrey;
  padding-bottom: 0.1rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-left: -0.5rem;
}
</style>
