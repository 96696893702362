<template>
  <v-col cols="12"
         md="12"
         lg="4"
  >
    <StockInfoCard
      v-for="(stock) in stocks"
      :key="stock.id"
      :v-if="!stocks.length > 0"
      :stock="stock"
      @getStocks="getStocks"
      :non-empty-rules="nonEmptyRules"
    />
    <h4>Добавить новый склад:</h4>
    <StockInfoCard
      :stock={}
      :empty-stock="true"
      @getStocks="getStocks"
      :non-empty-rules="nonEmptyRules"
    />
  </v-col>
</template>

<script>
import account from "@/api/account";
import StockInfoCard from "@/components/account/StockInfoCard.vue";

export default {
  name: "StockInfo",
  data: () => ({
    stocks: Array,
    stock: {
      city: String,
      id: Number,
      name: String,
      orders_to: Array,
    },
  }),
  props: {
    nonEmptyRules: {
      type: Array,
    },
  },
  components: {StockInfoCard},
  created() {
    this.getStocks();
  },
  methods: {
    getStocks() {
      account.getAccountStocksInfo().then(response => {
        this.stocks = response.data;
      });
    },
  }
}

</script>

<style scoped>

</style>
